var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Title","error-messages":errors},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","error-messages":errors},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"international account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"International account number","error-messages":errors},model:{value:(_vm.formData.international_account_number),callback:function ($$v) {_vm.$set(_vm.formData, "international_account_number", $$v)},expression:"formData.international_account_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"domestic account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Domestic account number","error-messages":errors},model:{value:(_vm.formData.domestic_account_number),callback:function ($$v) {_vm.$set(_vm.formData, "domestic_account_number", $$v)},expression:"formData.domestic_account_number"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-form>
    <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        v-if="loading"
        type="card"
      ></v-skeleton-loader>
    <v-container v-else>
      <v-row justify="center">
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="title" rules="required" v-slot="{errors}">
            <v-text-field
                label="Title"
                v-model="formData.title"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="account type" rules="required" v-slot="{errors}">
            <v-select
                label="Account type"
                v-model="formData.account_type"
                :items="dhlAccountTypes"
                item-text="title"
                item-value="value"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="export account number" rules="required" v-slot="{errors}">
            <v-text-field
                label="Export account number"
                v-model="formData.export_account_number"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="import account number" rules="required" v-slot="{errors}">
            <v-text-field
                label="Import account number"
                v-model="formData.import_account_number"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="site ID" rules="required" v-slot="{errors}">
            <v-text-field
                label="Site ID"
                v-model="formData.site_id"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="password" rules="required" v-slot="{errors}">
            <v-text-field
                label="Password"
                v-model="formData.password"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col
            cols="12"
            lg="8"
        >
          <ValidationProvider name="payment country" rules="required" v-slot="{errors}">
            <v-autocomplete
                label="Payment country"
                v-model="formData.payment_country"
                :items="countries"
                item-value="code"
                item-text="title"
                :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ShipperAccount from "@/models/ShipperAccount";
import { dhlAccountTypes } from "@/choices";
import Country from "@/models/Country";

export default {
  name: 'TNTAccountForm',
  props: {
    account: Object,
    value: Object,
  },
  data() {
    return {
      loading: false,
      courier: 'dhl',
      formData: {
        title: '',
        account_type: '',
        export_account_number: '',
        import_account_number: '',
        site_id: '',
        address: '',
        password: '',
        payment_country: '',
      },
      dhlAccountTypes
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    countries() {
      return Country.all()
    }
  },
  watch: {
    formData: {
      handler(value) {
        this.form = {...value}
      },
      deep: true
    },
    account: {
      async handler(value) {
        if (value) {
          this.loading = true;
          const accountResponse = await ShipperAccount.api().get(`/api/v1/couriers/shipper_accounts/${this.courier}/${this.account.id}`, {save: false});
          this.formData = {...accountResponse.response.data}
          this.loading = false;
        } else {
          this.formData = {}
        }
      },
      immediate: true
    }
  }
}
</script>

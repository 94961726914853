var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Title","error-messages":errors},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"account type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Account type","items":_vm.dhlAccountTypes,"item-text":"title","item-value":"value","error-messages":errors},model:{value:(_vm.formData.account_type),callback:function ($$v) {_vm.$set(_vm.formData, "account_type", $$v)},expression:"formData.account_type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"export account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Export account number","error-messages":errors},model:{value:(_vm.formData.export_account_number),callback:function ($$v) {_vm.$set(_vm.formData, "export_account_number", $$v)},expression:"formData.export_account_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"import account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Import account number","error-messages":errors},model:{value:(_vm.formData.import_account_number),callback:function ($$v) {_vm.$set(_vm.formData, "import_account_number", $$v)},expression:"formData.import_account_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"site ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Site ID","error-messages":errors},model:{value:(_vm.formData.site_id),callback:function ($$v) {_vm.$set(_vm.formData, "site_id", $$v)},expression:"formData.site_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"payment country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Payment country","items":_vm.countries,"item-value":"code","item-text":"title","error-messages":errors},model:{value:(_vm.formData.payment_country),callback:function ($$v) {_vm.$set(_vm.formData, "payment_country", $$v)},expression:"formData.payment_country"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
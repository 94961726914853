<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="600px"
  >
    <ValidationObserver v-slot="{invalid, reset}" ref="form">
      <v-form @reset.prevent="reset">
        <ValidationObserver v-slot="{invalid}">
          <v-card>
            <v-card-title>
              <span class="headline" v-if="account">Edit shipping account</span>
              <span class="headline" v-else>Add shipping account</span>
            </v-card-title>
            <v-card-text>
              <TNTAccountForm
                  v-if="courier === 'tnt'"
                  :account="account"
                  v-model="form"
              />
              <DHLAccountForm
                  v-if="courier === 'dhl'"
                  :account="account"
                  v-model="form"
              />
              <UPSAccountForm
                  v-if="courier === 'ups'"
                  :account="account"
                  v-model="form"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="onClose"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :disabled="invalid"
                  @click="onSave"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import TNTAccountForm from "@/views/dashboard/pages/Settings/ShippingAccounts/forms/TNTAccountForm";
import DHLAccountForm from "@/views/dashboard/pages/Settings/ShippingAccounts/forms/DHLAccountForm";
import UPSAccountForm from "@/views/dashboard/pages/Settings/ShippingAccounts/forms/UPSAccountForm";

export default {
  name: 'ShippingAccountModal',
  components: {UPSAccountForm, TNTAccountForm, DHLAccountForm},
  props: {
    account: Object,
    value: Boolean,
    courier: String
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.form = {};
    },
    onSave() {
      this.$emit('save', this.form)
      this.form = {};
    }
  }
}
</script>

<style scoped>
</style>

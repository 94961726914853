<template>
  <v-container
      id="regular-tables"
      fluid
      tag="section"
  >
    <v-row justify="center">
      <v-col
          cols="12"
          lg="8"
      >
        <v-row>
          <v-col>
            <v-tabs
                v-model="tab"
                align-with-title
                background-color="transparent"
            >
              <v-tab
                  v-for="item in tabs"
                 :key="item.courier"
                  @click="selectCourier(item.courier)"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>

            <v-row v-if="loading">
              <v-col
                  cols="3"
                  v-for="index in 3"
                  :key="index"
              >
                <v-skeleton-loader
                    class="mx-auto"
                    type="card"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                v-for="(account, index) in accounts[selectedCourier]"
                :key="index"
                cols="3"
              >
                <v-card>
                  <v-card-title class="justify-center">{{account.title}}</v-card-title>
                  <v-card-text
                      :class="{'text-center': true, 'py-3': true, 'cursor-pointer': account.owned}"
                      @click="selectAccount(account)">
                    <img :src="courierLogos[selectedCourier]" :alt="selectedCourier" />
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <template v-if="account.owned">
                      <v-btn text small @click="selectAccount(account)">Edit</v-btn>
                      <v-btn text small color="error" @click="deleteAccount(account)">Delete</v-btn>
                    </template>
                    <template v-else>
                      <v-chip
                          small
                          label
                          color="red"
                          text-color="white"
                          v-if="!account.owned"
                      >
                        MyBoxhub account
                      </v-chip>
                    </template>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card height="100%" class="d-flex flex-column" @click="onAddNew">
                  <v-card-title class="text-uppercase justify-center">Add new</v-card-title>
                  <v-card-text class="d-flex flex-grow-1 justify-center align-center">
                    <v-icon style="font-size: 5em">mdi-plus</v-icon>
                  </v-card-text>
                  <v-card-actions></v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ShippingAccountModal
        v-model="showModal"
        :account="selectedAccount"
        :courier="selectedCourier"
        @close="onModalClose"
        @save="onModalSave"
      />
    <v-dialog
        v-model="confirmDeleteDialog"
        max-width="600px"
    >
      <v-card v-if="selectedAccount">
        <v-card-title>
          <span class="headline">Confirm</span>
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to delete this item?</p>
          <p><strong>{{selectedAccount.title}}</strong></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="onCloseDelete"
          >
            Close
          </v-btn>
          <v-btn
              color="error darken-1"
              text
              @click="onConfirmDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import ShipperAccount from "@/models/ShipperAccount";
import ShippingAccountModal from "@/views/dashboard/pages/Settings/ShippingAccounts/ShippingAccountModal";
import {courierLogos} from "@/choices";

export default {
  name: 'ShippingAccounts',
  components: {
    ShippingAccountModal
  },
  data() {
    return {
      tab: null,
      loading: true,
      selectedCourier: 'tnt',
      selectedAccount: null,
      tabs: [
        {title: 'TNT', courier: 'tnt'},
        {title: 'DHL', courier: 'dhl'},
        {title: 'UPS', courier: 'ups'},
      ],
      courierLogos,
      overlay: false,
      showModal: false,
      confirmDeleteDialog: false
    }
  },
  computed: {
    accounts() {
      return {
        tnt: ShipperAccount.query().where('courier', 'tnt').get(),
        dhl: ShipperAccount.query().where('courier', 'dhl').get(),
        ups: ShipperAccount.query().where('courier', 'ups').get()
      }
    }
  },
  methods: {
    deleteAccount(account) {
      this.confirmDeleteDialog = true;
      this.selectedAccount = account;
    },
    selectCourier(courier) {
      this.selectedCourier = courier;
      this.selectedAccount = null;
    },
    selectAccount(account) {
      if (!account.owned) {
        return
      }
      this.selectedAccount = account;
      this.showModal = true;
    },
    onModalClose() {
      this.showModal = false;
      this.selectedAccount = null;
    },
    async onModalSave(data) {
      if (data.id) {
        await ShipperAccount.api().put(`/api/v1/couriers/shipper_accounts/${this.selectedCourier}/${data.id}/`, data)
      } else {
        await ShipperAccount.api().post(`/api/v1/couriers/shipper_accounts/${this.selectedCourier}/`, data)
      }
      this.showModal = false;
      this.selectedAccount = null;
    },
    onAddNew() {
      this.selectedAccount = null;
      this.showModal = true;
    },
    async onConfirmDelete() {
      this.loading = true;
      await ShipperAccount.api().delete(`/api/v1/couriers/shipper_accounts/${this.selectedCourier}/${this.selectedAccount.id}/`);
      await ShipperAccount.api().get('/api/v1/couriers/shipper_accounts/')
      await ShipperAccount.delete(this.selectedAccount.id);
      this.loading = false;
      this.selectedAccount = null;
      this.confirmDeleteDialog = false;
    },
    onCloseDelete() {
      this.selectedAccount = null;
      this.confirmDeleteDialog = false;
    }

  },
  async mounted() {
    this.loading = true;
    await ShipperAccount.api().get('/api/v1/couriers/shipper_accounts/')
    this.loading = false;
  }
}
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>